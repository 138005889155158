<template>
  <div>
    <div :key="updateKey">
      <timeChoice :data="weekdays[index][0]" :callback="function(time) { updateKey = new Date().getTime(); changeHour(index, time, 'begin') }"/>
      <flixIcon class="arrow-right" :id="'arrow-right'" />
      <timeChoice :data="weekdays[index][1]" :callback="function(time) { updateKey = new Date().getTime(); changeHour(index, time, 'end') }"/> {{ $t('message.oClock') }}

      <div  v-if="checkDate(index)" class="flix-text-danger" style="margin-top: 6px">
        <flixIcon :id="'warning-sign'"   />&nbsp;
        {{ $t('message.check', { name: $t('message.timeRange') }) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    timeChoice () { return import('@/components/assistent/components/assets/getTime') }
  },
  props: { weekdays: [Object, Array], index: [Number, String], callback: Function },
  data () {
    return {
      updateKey: 0
    }
  },
  methods: {
    getStatus (index) {
      if (typeof this.weekdays[index] === 'undefined') {
        return false
      }

      return true
    },
    checkDate (index) {
      var date = [
        this.weekdays[index][0],
        this.weekdays[index][1]
      ]
      date[0] = date[0].split(':')
      date[1] = date[1].split(':')

      date[0] = this.$getNullsBefore(date[0][0]) + '' + this.$getNullsBefore(date[0][1])
      date[1] = this.$getNullsBefore(date[1][0]) + '' + this.$getNullsBefore(date[1][1])

      if (date[0] > date[1]) {
        return true
      }

      return false
    },
    changeHour (index, time, status) {
      var insert = this.$getNullsBefore(time.hour) + ':' + this.$getNullsBefore(time.minute)
      if (status === 'begin') {
        this.weekdays[index][0] = insert
      } else if (status === 'end') {
        this.weekdays[index][1] = insert
      }
      this.updateKey = new Date().getTime()
      this.callback({ include: { weekdays: this.weekdays } })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .arrow-right
    margin: 0 5px

  .icon, .arrow-right
    font-size: 16pt
</style>
